<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 采购结算
 * @Date: 2020-12-24 22:18:17
 * @LastEditors: OBKoro1
 * @LastEditTime: 2020-12-24 23:33:46
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/Purchase_settlement.vue
-->
<template>
  <div class="user_content">
    <van-tabs v-model="active" background="#f6f6f6" class="tabpu" @click="onClick">
      <van-tab title="累计" />
      <van-tab title="今日" />
      <van-tab title="昨日" />
      <van-tab title="本月" />
    </van-tabs>
    <div class="purch">
      <div class="mae">
        <span>结算金额:</span><span>¥{{ totalMoney }}</span>
      </div>
      <ul>
        <li class="pur_item">
          <div class="head" />
          <div class="info">
            <div>A 泰国芽庄渝东北洪磊</div>
            <div>2020-06-11 00:06:58</div>
          </div>
          <div class="sett">结算收益：+0.34</div>
        </li>

      </ul>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Tab, Tabs } from 'vant'

Vue.use(Tab)
Vue.use(Tabs)
import {
  purchase
} from '@/services/userApi'

export default {
  data() {
    return {
      active: 0,
      time: 1,
      list: [],
      totalMoney: ''
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      const parm = {
        time: this.active + 1,
        type: 1,
        page: 1
      }
      purchase(parm).then(res => {
        if (Number(res.code) === 200) {
          this.list = res.data.list
          this.totalMoney = res.data.totalMoney
        }
      })
    },
    onClick() {
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  padding-bottom: 80px;

  .van-tabs__nav .van-tabs__nav--line {
    box-shadow: -10px -2px -2px #888888;
  }

  .purch {
    margin: 13px;

    .mae {
      display: flex;
      justify-content: space-between;
    }

    .pur_item {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .head {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background: #F2F2F2;
      }

      .info {
        & > div:nth-child(1) {
          font-size: 14px;
        }

        & > div:nth-child(2) {
          color: #999999;
          font-size: 10px;
          text-align: left;
          margin-top: 6px;
        }
      }

      .sett {
        color: #E33F44;
        font-size: 13px;
        font-weight: 500;
      }
    }
  }
}
</style>
